import * as SystemMetaInformation from "framework/models/systemMetaInformation";

import { autoSelectCompany, autoSelectSystem } from "application/actionCreators/systemActionCreators";
import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { getRmsContext } from "application/contextSelection/contextSelectionHelper";

export function handleUrlParameters() {
    const storageManager = StorageManager.GetInstance();

    const { companyId, systemName } = getRmsContext();

    const existingSystem = storageManager.retrieve("staff.user.settings.initSystem", EStorageType.LocalStorage, true);
    storageManager.remove("staff.user.settings.initSystem", EStorageType.LocalStorage, true);

    if (companyId) {
        autoSelectCompany(companyId);
    } else if (systemName) {
        autoSelectSystem(systemName);
    } else if (existingSystem) {
        const selectedSystem = SystemMetaInformation.Record(JSON.parse(existingSystem));
        if (selectedSystem.companyId) {
            autoSelectCompany(selectedSystem.companyId);
        } else {
            autoSelectSystem(selectedSystem.name);
        }
    }
}
