import * as Actions from "framework/actions/apiActions";
import * as UserActions from "framework/actions/userActions";
import * as EventActions from "application/actions/eventActions";

import IAPISettings from "framework/models/IAPISettings";
import UserStore from "framework/stores/userStore";
import Store from "framework/stores/store";
import { Map } from "immutable";

import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { SystemStore } from "application/stores/systemStore";
import { systemChanged } from "application/actions/systemActions";

declare const API_URL: string;
declare const IS_DEVELOPMENT: boolean;
declare const IS_STAGING: boolean;
declare const IS_TRACKINGDEV: boolean;
const IS_PROD = !IS_DEVELOPMENT && !IS_STAGING;
interface IAPIStore {
    getSettings(): IAPISettings;
    usingDefaultApiUrl(): boolean;
    getDefaultApiUrl(): string;
}

export const getProfilerResultUrl = (apiUrl: string, id: string, authToken: string | null) => {
    return apiUrl + "/profiler/results?id=" + id + "&authorization=" + authToken;
};

class APIStore extends Store implements IAPIStore {
    private readonly _storageManager: StorageManager;

    private _apiUrl: string;
    private _defaultApiUrl: string;
    private _connectionId: string;
    private _isProd: boolean;
    private _isTrackingDev: boolean;
    private _isStaging: boolean;
    constructor() {
        super();
        this._storageManager = StorageManager.GetInstance();

        this.handle(Actions.ChangeAPIUrl.actionType, this._handleChangeAPIUrl);
        this.handle(Actions.AddAPIProfilerIds.actionType, this._handleAddAPIProfilerIds);
        this.handle(UserActions.LoginSuccess.actionType, this._handleChangeToken);
        this.handle(systemChanged.type, this._handleChangeSelectedSystem);
        this.handle(EventActions.SetConnectionId.actionType, this._handleChangeConnectionId);

        this._apiUrl = API_URL;
        this._isProd = IS_PROD;
        this._isTrackingDev = IS_TRACKINGDEV;
        this._isStaging = IS_STAGING;

        if (document.location.href.indexOf("webplatform-trackingdev.azure.caspeco.com") > -1 || document.location.href.indexOf("webplatform-trackingdev.azurewebsites.net") > -1) {
            this._apiUrl = "//marc-trackingdev-as-ne.azure.caspeco.com/";
        }

        // remember the defaultApiUrl before getting a custom one
        this._defaultApiUrl = this._apiUrl;
        this._apiUrl = this._storageManager.retrieve("staff.api.settings.url", EStorageType.LocalStorage, true) || this._apiUrl;
    }

    private _handleChangeAPIUrl = (action: Actions.ChangeAPIUrl) => {
        this._apiUrl = action.url;
        if (action.remove) this._storageManager.remove("staff.api.settings.url", EStorageType.LocalStorage, true);
        else this._storageManager.store("staff.api.settings.url", this._apiUrl, EStorageType.LocalStorage, true);
        this.emitChange();
    };

    private _profilesRaw: Map<string, any> = Map<string, any>();
    private _fetching: Map<string, boolean> = Map<string, boolean>();
    private _handleAddAPIProfilerIds = async (action: Actions.AddAPIProfilerIds) => {
        const settings = this.getSettings();
        await Promise.all(
            action.ids.map(async (id) => {
                // todo: Check if ongoing request to avoid double fetching
                if (!this._fetching.get(id)) {
                    this._fetching = this._fetching.set(id, true);

                    // apiUrl can be value from marc-deployment headers, then we need to turn it into url
                    let profiledApi = settings.apiUrl;
                    if (action.deployment) {
                        profiledApi =
                            this._isProd || this._isTrackingDev
                                ? "https://marc-" + action.deployment + ".azure.caspeco.com"
                                : this._isStaging
                                ? "https://marc-" + action.deployment + ".stage.caspeco.net"
                                : "https://marc-" + action.deployment + ".dev.caspeco.net";
                    }

                    const res = await fetch(getProfilerResultUrl(profiledApi, id, settings.authorizationToken), { headers: { accept: "application/json" } });
                    const data = await res.json();

                    data.profiledApi = profiledApi;
                    this._profilesRaw = this._profilesRaw.set(data.Id, data);
                }
            })
        );
        this.emitChange();
    };

    getProfilerIds() {
        return this._profilesRaw;
    }

    clearProfiles() {
        this._profilesRaw = Map<string, any>();
        this.emitChange();
    }

    private _handleChangeSelectedSystem = () => {
        this.emitChange();
    };

    private _handleChangeToken = (_action: UserActions.LoginSuccess) => {
        this.emitChange();
    };

    private _handleChangeConnectionId = (action: EventActions.SetConnectionId) => {
        this._connectionId = action.connectionId;
        this.emitChange();
    };

    usingDefaultApiUrl() {
        return this._defaultApiUrl === this._apiUrl;
    }

    getDefaultApiUrl() {
        return this._defaultApiUrl;
    }

    public getState(): IAPISettings {
        const systemStoreState = SystemStore.getState();
        const systemName = systemStoreState.selectedSystem?.name;
        const companyId = systemStoreState.selectedSystem?.companyId;

        return {
            apiUrl: this._apiUrl,
            apiVersion: "v1",
            authorizationToken: UserStore.getAccessTokenString(),
            tokenValidUntil: UserStore.getTokenValidUntil(),
            applicationName: "Mobile",
            defaultAccepts: "application/json+optimized, application/json",
            defaultContentType: "application/json",
            systemName: systemName!,
            companyId: companyId,
            connectionId: this._connectionId,
        };
    }

    public getSettings(): IAPISettings {
        const systemStoreState = SystemStore.getState();
        const systemName = systemStoreState.selectedSystem?.name;
        const companyId = systemStoreState.selectedSystem?.companyId;

        return {
            apiUrl: this._apiUrl,
            apiVersion: "v1",
            authorizationToken: UserStore.getAccessTokenString(),
            tokenValidUntil: UserStore.getTokenValidUntil(),
            applicationName: "Mobile",
            defaultAccepts: "application/json+optimized, application/json",
            defaultContentType: "application/json",
            systemName: systemName!,
            companyId: companyId,
            connectionId: this._connectionId,
        };
    }
}
const apiStore = new APIStore();
export default apiStore;
