import * as CompanyInformation from "application/models/management/companyInformation";
import * as SystemTag from "application/models/management/systemTag";
import { IsoCountryCode } from "application/models/management/user";
import * as Immutable from "immutable";
import * as Moment from "moment-timezone";

export enum SystemType {
    Live = "Live",
    Clone = "Clone",
    // todo: Reserved
}

export interface ISystemMetaInformation extends Immutable.Map<string, any> {
    name: string;
    commonName: string;
    companyName: string;
    companyId: string | undefined;
    mode: string;
    countryCode: IsoCountryCode;
    deploymentWebPlatform: string;
    deploymentMarc: string;
    type: SystemType;
    isLive: boolean;
    createdAt: IMoment;
    createdByUsername: string;
    createdBySubject: string;
    lastActiveAt: IMoment;
    lastActiveUsername: string;
    lastActiveSubject: string;
    estimatedRequestCount: number;
    systemTags: Immutable.List<SystemTag.ISystemTagRecord>;
    companyInformation: CompanyInformation.CompanyInformation;
    recent: boolean;
    organizationCode: string;
    isProtected: boolean;
    expiryDate: IMoment;
    deploymentMarcSuffix: string | null;

    // These are not stored in DB
    isFavourite?: boolean;
    isRecentlyUsed?: boolean;
}

const SystemMetaInformationRecord = Immutable.Record({
    name: "",
    commonName: "",
    companyName: "",
    companyId: undefined,
    mode: "",
    countryCode: IsoCountryCode.None,
    deploymentWebPlatform: null,
    deploymentMarc: null,
    type: SystemType,
    isLive: false,
    createdAt: null,
    createdByUserName: null,
    createdBySubject: null,
    lastActiveAt: null,
    lastActiveUsername: null,
    lastActiveSubject: null,
    estimatedRequestCount: 0,
    systemTags: Immutable.List<SystemTag.ISystemTagRecord>(),
    companyInformation: null,
    recent: false,
    organizationCode: null,
    isProtected: false,
    expiryDate: null,
    deploymentMarcSuffix: null,
});

export function Record(values: Partial<ISystemMetaInformation> & { name: string }): ISystemMetaInformation {
    const modifiedValues: Partial<ISystemMetaInformation> = {};
    // get systemType
    const type = getSystemType(values.name);
    modifiedValues.type = type && SystemType[type];
    modifiedValues.isLive = type === SystemType.Live;
    modifiedValues.createdAt = values.createdAt ? Moment(values.createdAt) : undefined;
    modifiedValues.lastActiveAt = values.lastActiveAt ? Moment(values.lastActiveAt) : undefined;
    if (values.systemTags) {
        modifiedValues.systemTags = Immutable.List<SystemTag.ISystemTagRecord>(values.systemTags.map((t) => SystemTag.SystemTagRecord(t)));
    }
    if (values.companyInformation) {
        modifiedValues.companyInformation = new CompanyInformation.CompanyInformation(values.companyInformation);
    }
    return <ISystemMetaInformation>(<any>new SystemMetaInformationRecord({ ...values, ...modifiedValues }));
}

function getSystemType(systemName: string) {
    if (!systemName) {
        return undefined;
    }

    // remove three first prefix, e.g: "se_"
    const withoutCountry = systemName.substr(3);

    //todo: Add support for reserved systems
    if (withoutCountry.indexOf("_") === 0) {
        return SystemType.Clone;
    } else {
        return SystemType.Live;
    }
}
