import { List } from "immutable";
import { createAction } from "@reduxjs/toolkit";
import { ISystemRecord } from "application/models/management/system";
import { ISystemMetaInformation } from "framework/models/systemMetaInformation";
import { IdentitySystemMetaInformation } from "framework/models/identitySystemMetaInformation";
import { ICompanyInformationRecord } from "application/models/management/companyInformation";

export class SetSystemProps {
    system: string;
    companyId: string | undefined;
    redirect: boolean;
    autoSelected: boolean = false;
}

interface LoadSystemSuccess {
    system: ISystemMetaInformation;
    keepForFieldSelector?: boolean;
}

export const loadingSystems = createAction("system_redux_loadingSystems");
export const loadSystemsSuccess = createAction<List<ISystemMetaInformation>>("system_redux_loadSystemsSuccess");
export const loadSystemSuccess = createAction<LoadSystemSuccess>("system_redux_loadSystemSuccess");
export const loadSelectedSystemsSuccess = createAction<List<ISystemMetaInformation>>("system_redux_loadSelectedSystemsSuccess");
export const loadingSearchSystems = createAction("system_redux_loadingSearchSystems");
export const searchSystemsSuccess = createAction<{ systems: List<ISystemMetaInformation>; purgeOtherSystems: boolean }>("system_redux_searchSystemsSuccess");

export const resetToFavouritesRecently = createAction("system_redux_resetState");

export const setSystem = createAction<SetSystemProps>("system_redux_setSystem");
export const systemChanged = createAction("system_redux_systemChanged");
export const setSystemSSO = createAction("system_redux_setSystemSSO");
export const removeSystem = createAction<ISystemRecord>("system_redux_removeSystem");

export const updateSystemNameSucess = createAction<ICompanyInformationRecord>("system_updateSystemNameSucess");

export const loadingSystemPreferences = createAction("system_redux_loadingSystemPreferences");
export const loadSystemPreferencesSuccess = createAction<List<IdentitySystemMetaInformation>>("system_redux_loadSystemPreferencesSuccess");

export const loadingFavourites = createAction("system_redux_loadingFavourites");
export const loadFavouritesSuccess = createAction<List<ISystemMetaInformation>>("system_redux_loadFavouritesSuccess");

export const loadingRecentlyUsed = createAction("system_redux_loadingRecentlyUsed");
export const loadRecentlyUsedSuccess = createAction<List<ISystemMetaInformation>>("system_redux_loadRecentlyUsedSuccess");

export const addFavouriteSuccess = createAction<ISystemMetaInformation>("system_redux_addFavouriteSuccess");
export const removeFavouriteSuccess = createAction<ISystemMetaInformation>("system_redux_removeFavouriteSuccess");

export const addRecentlyUsedSuccess = createAction<ISystemMetaInformation>("system_redux_addRecentlyUsedSuccess");
export const removeRecentlyUsedSuccess = createAction<ISystemMetaInformation>("system_redux_removeRecentlyUsedSuccess");

export const removeAllRecentlyUsedSuccess = createAction("system_redux_removeAllRecentlyUsedSuccess");

export const addSystemToKeepForFields = createAction<ISystemMetaInformation>("system_addSystemToKeepForFields");
