import { List } from "immutable";
import { Result } from "neverthrow";
import { promiseToResult } from "framework/modules/requestHelper";
import { APIBase, RequestBaseError } from "framework/modules/apiBase";
import { ISystemMetaInformation } from "framework/models/systemMetaInformation";
import { IdentitySystemMetaInformation } from "framework/models/identitySystemMetaInformation";

import * as SystemMetaInformation from "framework/models/systemMetaInformation";

class SystemApi extends APIBase {
    constructor() {
        super();
        this.setApiVersion("v2/systems");
    }

    public loadSystems(): Promise<Result<List<ISystemMetaInformation>, RequestBaseError>> {
        return promiseToResult(this.getAsync("/me", (data: any) => List<ISystemMetaInformation>(data.map((s: ISystemMetaInformation) => SystemMetaInformation.Record(s))), { params: [] }));
    }

    public loadSelectedSystems(systems: List<string>): Promise<Result<List<ISystemMetaInformation>, RequestBaseError>> {
        return promiseToResult(
            this.postAsync("/getSystems", (data: any) => List<ISystemMetaInformation>(data.map((s: ISystemMetaInformation) => SystemMetaInformation.Record(s))), { data: systems })
        );
    }

    public loadSystem(systemName: string): Promise<Result<ISystemMetaInformation, RequestBaseError>> {
        return promiseToResult(this.getAsync("/getsystem?systemName={0}", (data: any) => SystemMetaInformation.Record(data), { params: [systemName] }));
    }

    public loadSystemByCompanyId(companyId: string): Promise<Result<ISystemMetaInformation, RequestBaseError>> {
        return promiseToResult(this.getAsync("/getsystemByCompanyId?companyId={0}", (data: any) => SystemMetaInformation.Record(data), { params: [companyId] }));
    }

    public searchSystems(searchQuery: string): Promise<Result<List<ISystemMetaInformation>, RequestBaseError>> {
        return promiseToResult(
            this.getAsync("/search/{0}", (data: any) => List<ISystemMetaInformation>(data.map((s: ISystemMetaInformation) => SystemMetaInformation.Record(s))), {
                params: [encodeURIComponent(searchQuery)],
                abortKey: "searchSystems",
                abortPayload: List(),
            })
        );
    }

    public loadSystemPreferences(): Promise<Result<List<IdentitySystemMetaInformation>, RequestBaseError>> {
        return promiseToResult(this.getAsync("/getSystemPreferences", (data: any) => List<IdentitySystemMetaInformation>(data), { params: [] }));
    }

    public loadFavourites(): Promise<Result<List<ISystemMetaInformation>, RequestBaseError>> {
        return promiseToResult(
            this.getAsync("/favourites", (data: any) => List<ISystemMetaInformation>(data.map((s: ISystemMetaInformation) => SystemMetaInformation.Record(s))), { params: [] })
        );
    }

    public loadRecentlyUsed(): Promise<Result<List<ISystemMetaInformation>, RequestBaseError>> {
        return promiseToResult(
            this.getAsync("/recentlyused", (data: any) => List<ISystemMetaInformation>(data.map((s: ISystemMetaInformation) => SystemMetaInformation.Record(s))), { params: [] })
        );
    }

    public addFavourite(systemName: string): Promise<Result<boolean, RequestBaseError>> {
        return promiseToResult(this.putAsync("/addfavourite/{0}", (data) => <boolean>data, { params: [systemName] }));
    }

    public removeFavourite(systemName: string): Promise<Result<boolean, RequestBaseError>> {
        return promiseToResult(this.deleteAsync("/removefavourite/{0}", (data) => <boolean>data, { params: [systemName] }));
    }

    public addRecentlyUsed(systemName: string): Promise<Result<boolean, RequestBaseError>> {
        return promiseToResult(this.putAsync("/addrecentlyused/{0}", (data) => <boolean>data, { params: [systemName] }));
    }

    public removeRecentlyUsed(systemName: string): Promise<Result<boolean, RequestBaseError>> {
        return promiseToResult(this.deleteAsync("/removerecentlyused/{0}", (data) => <boolean>data, { params: [systemName] }));
    }

    public removeAllRecentlyUsed(): Promise<Result<boolean, RequestBaseError>> {
        return promiseToResult(this.deleteAsync("/removeallrecentlyused", (data) => <boolean>data));
    }
}

export const systemApi = new SystemApi();
