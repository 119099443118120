import ToastType from "framework/models/toaster/toasterTypeEnum";

import * as SystemMetaInformation from "framework/models/systemMetaInformation";

import { dispatch } from "framework/actions/action";
import { Add } from "framework/actions/toastActions";
import { systemApi } from "application/api/systemApi";
import { Toast } from "framework/models/toaster/toast";
import { SystemStore } from "application/stores/systemStore";
import { hasPermission } from "framework/modules/permissionHelper";
import { LoadingStatus } from "framework/models/loadingStatusEnum";
import { IsoCountryCode } from "application/models/management/user";
import { ISystemMetaInformation } from "framework/models/systemMetaInformation";
import { EStorageType, StorageManager } from "application/storageManager/storageManager";
import { List } from "immutable";
import {
    addFavouriteSuccess,
    removeFavouriteSuccess,
    loadingFavourites,
    loadFavouritesSuccess,
    loadRecentlyUsedSuccess,
    loadingRecentlyUsed,
    addRecentlyUsedSuccess,
    removeRecentlyUsedSuccess,
    removeAllRecentlyUsedSuccess,
    loadSystemsSuccess,
    loadingSystems,
    loadingSystemPreferences,
    loadSystemPreferencesSuccess,
    loadSystemSuccess,
    searchSystemsSuccess,
    loadingSearchSystems,
    resetToFavouritesRecently,
    addSystemToKeepForFields,
    setSystem,
    systemChanged,
} from "application/actions/systemActions";

export const getSystem = (systemName: string): ISystemMetaInformation => {
    const store = SystemStore.getState();
    const systems = store.systems;

    if (systems.count() === 0) {
        return SystemMetaInformation.Record({
            name: systemName,
            commonName: systemName,
        });
    }

    return systems.find((system) => system.name === systemName);
};

export const getSelectedSystem = (): ISystemMetaInformation => {
    const store = SystemStore.getState();
    return store.selectedSystem!;
};

export const getSelectedSystemName = () => {
    const store = SystemStore.getState();
    const selectedSystem = store.selectedSystem;
    return selectedSystem?.name ?? "";
};

export const getSelectedBCSystemName = () => {
    return getSelectedSystemName().substring(3);
};

export const getSelectedSystemCountryCode = () => {
    const selectedSystem = getSelectedSystem();
    if (selectedSystem) {
        return selectedSystem.countryCode;
    } else {
        return IsoCountryCode.None;
    }
};

export const getSystems = (): List<ISystemMetaInformation> => {
    const store = SystemStore.getState();
    let systems = store.systems;
    store.systemsForFields.forEach((sff) => {
        if (!systems.some((s) => s.name === sff.name)) {
            systems = systems.push(sff);
        }
    });

    return systems;
};

export const getSystemPreferences = () => {
    const store = SystemStore.getState();
    return store.systemPreferences;
};

export const resetSystemsToDefault = async (): Promise<void> => {
    dispatch(resetToFavouritesRecently());
};

export const setSelectedSystem = (system: string) => {
    dispatch(systemChanged());
    dispatch(setSystem({ system: system, companyId: undefined, redirect: false, autoSelected: false }));
};

export const autoSelectSystem = (system: string) => {
    dispatch(systemChanged());
    dispatch(setSystem({ system: system, companyId: undefined, redirect: false, autoSelected: true }));
};

export const autoSelectCompany = (companyId: string) => {
    dispatch(systemChanged());
    dispatch(setSystem({ companyId: companyId, system: "", redirect: false, autoSelected: true }));
};

export const setSelectedSystemRedirectTo = (system: string, redirectTo: string, userId: string) => {
    const storageManager = StorageManager.GetInstance();
    storageManager.store("staff.user.settings.initialRedirect", redirectTo, EStorageType.LocalStorage, true);
    storageManager.setPrefix(system, userId);

    dispatch(setSystem({ system: system, companyId: undefined, redirect: true, autoSelected: false }));
};

export const loadSystems = async (forceReload?: boolean): Promise<void> => {
    const store = SystemStore.getState();
    if (store.systemsStatus === LoadingStatus.NotLoaded || forceReload) {
        dispatch(loadingSystems());
        const result = await systemApi.loadSystems();
        if (result.isOk() && result.value) {
            dispatch(loadSystemsSuccess(result.value));
        } else {
            new Add(
                new Toast({
                    heading: "Error",
                    message: "Failed to load systems",
                    type: ToastType.Error,
                })
            );
        }
    }
};

export const loadSelectedSystems = async (systems: List<string>): Promise<void> => {
    const result = await systemApi.loadSelectedSystems(systems);
    if (result.isOk() && result.value) {
        dispatch(searchSystemsSuccess({ systems: result.value, purgeOtherSystems: false }));
    } else {
        new Add(
            new Toast({
                heading: "Error",
                message: "Failed to load systems",
                type: ToastType.Error,
            })
        );
    }
};

export const searchSystems = async (searchQuery: string, purgeOtherSystems: boolean): Promise<void> => {
    dispatch(loadingSearchSystems());
    const result = await systemApi.searchSystems(searchQuery);
    if (result.isOk() && result.value) {
        dispatch(searchSystemsSuccess({ systems: result.value, purgeOtherSystems }));
    } else {
        // Ignored
    }
};

export const loadSystem = async (systemName: string, keepForFieldSelector?: boolean): Promise<void> => {
    const result = await systemApi.loadSystem(systemName);
    if (result.isOk() && result.value) {
        dispatch(loadSystemSuccess({ system: result.value, keepForFieldSelector }));
    } else {
        new Add(
            new Toast({
                heading: "Error",
                message: "Failed to load system",
                type: ToastType.Error,
            })
        );
    }
};

export const loadSystemByCompanyId = async (companyId: string, keepForFieldSelector?: boolean): Promise<void> => {
    const result = await systemApi.loadSystemByCompanyId(companyId);
    if (result.isOk() && result.value) {
        dispatch(loadSystemSuccess({ system: result.value, keepForFieldSelector }));
    } else {
        new Add(
            new Toast({
                heading: "Error",
                message: "Failed to load system by company id",
                type: ToastType.Error,
            })
        );
    }
};

export const keepSystemForFields = async (system: ISystemMetaInformation): Promise<void> => {
    dispatch(addSystemToKeepForFields(system));
};

export const loadSystemPreferences = async (forceReload?: boolean): Promise<void> => {
    const store = SystemStore.getState();
    if (store.systemPreferencesStatus === LoadingStatus.NotLoaded || forceReload) {
        dispatch(loadingSystemPreferences());
        const result = await systemApi.loadSystemPreferences();
        if (result.isOk() && result.value) {
            dispatch(loadSystemPreferencesSuccess(result.value));
        } else {
            new Add(
                new Toast({
                    heading: "Error",
                    message: "Failed to load systemPreferences",
                    type: ToastType.Error,
                })
            );
        }
    }
};

export const loadFavouritesAndRecent = async (): Promise<void> => {
    if (hasPermission("caspeco.support")) {
        await loadFavourites();
        await loadRecentlyUsed();
    }
};

export const loadFavourites = async (): Promise<void> => {
    const store = SystemStore.getState();
    if (store.favouriteSystemsStatus === LoadingStatus.NotLoaded) {
        dispatch(loadingFavourites());
        const result = await systemApi.loadFavourites();
        if (result.isOk() && result.value) {
            dispatch(loadFavouritesSuccess(result.value));
        } else {
            new Add(
                new Toast({
                    heading: "Error",
                    message: "Failed to load favourites",
                    type: ToastType.Error,
                })
            );
        }
    }
};

export const loadRecentlyUsed = async (): Promise<void> => {
    const store = SystemStore.getState();
    if (store.recentlyUsedSystemsStatus === LoadingStatus.NotLoaded) {
        dispatch(loadingRecentlyUsed());
        const result = await systemApi.loadRecentlyUsed();
        if (result.isOk() && result.value) {
            dispatch(loadRecentlyUsedSuccess(result.value));
        } else {
            new Add(
                new Toast({
                    heading: "Error",
                    message: "Failed to load recently used",
                    type: ToastType.Error,
                })
            );
        }
    }
};

export const addFavourite = async (system: ISystemMetaInformation) => {
    const result = await systemApi.addFavourite(system.name);
    if (result.isOk() && result.value) {
        dispatch(addFavouriteSuccess(system));
    } else if (result.isErr()) {
        failToastAction(result.error);
    }
};

export const removeFavourite = async (system: ISystemMetaInformation) => {
    const result = await systemApi.removeFavourite(system.name);
    if (result.isOk() && result.value) {
        dispatch(removeFavouriteSuccess(system));
    } else if (result.isErr()) {
        failToastAction(result.error);
    }
};

export const addRecentlyUsed = async (system: ISystemMetaInformation) => {
    const result = await systemApi.addRecentlyUsed(system.name);
    if (result.isOk() && result.value) {
        dispatch(addRecentlyUsedSuccess(system));
    } else if (result.isErr()) {
        failToastAction(result.error);
    }
};

export const removeRecentlyUsed = async (system: ISystemMetaInformation) => {
    const result = await systemApi.removeRecentlyUsed(system.name);
    if (result.isOk() && result.value) {
        dispatch(removeRecentlyUsedSuccess(system));
    } else if (result.isErr()) {
        failToastAction(result.error);
    }
};

export const removeAllRecentlyUsed = async () => {
    const result = await systemApi.removeAllRecentlyUsed();
    if (result.isOk() && result.value) {
        dispatch(removeAllRecentlyUsedSuccess());
    } else if (result.isErr()) {
        failToastAction(result.error);
    }
};

function failToastAction(error: any) {
    let errorMessage = "";
    if (error.error) {
        try {
            const errors = Object.values(JSON.parse(error.error).errors).flat();
            errors.forEach((o) => {
                errorMessage = errorMessage.concat(`\n${o}`);
            });
        } catch (_ex) {
            if (typeof error.error === "string") {
                errorMessage = error.error;
            } else {
                errorMessage = error.error.message ?? error.message ?? "";
            }
        }
    }
    new Add(
        new Toast({
            heading: "Error",
            message: errorMessage,
            type: ToastType.Error,
        })
    );
}
