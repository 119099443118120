import { getRmsContext } from "application/contextSelection/contextSelectionHelper";
import { EStorageType } from "application/storageManager/storageManager";
import * as PermissionActions from "framework/actions/permissionActions";
import * as API from "framework/modules/apiBase";

interface IPermissionAPI {
    loadAll(): void;
}

class PermissionAPI extends API.APIBase implements IPermissionAPI {
    private _requestId: number;

    constructor() {
        super();
        this._requestId = 0;
        this.setApiVersion("v1");
    }

    loadAll() {
        const pendingOperation = { method: this.loadAll };
        this.loadPending(pendingOperation);
        this._requestId += 1;
        const currentRequestId = this._requestId;

        this.get("permissions/me", { allowOnlyOne: false })
            .then(
                (result) => {
                    // Store server cache keys
                    this._storageManager.store("staff.globalCacheKeys", JSON.stringify(result.data.globalCacheKeys) || "", EStorageType.LocalStorage, true);
                    // Store system cache keys
                    const { companyId, systemName } = getRmsContext();
                    const cacheKey = companyId ?? systemName;
                    if (cacheKey) {
                        this._storageManager.store(`staff.${cacheKey}:cacheKeys`, JSON.stringify(result.data.systemCacheKeys) || "", EStorageType.LocalStorage, true);
                    }
                    // Set current deployments, used when reading cached responses
                    this._storageManager.store("staff.currentUiDeployment", result.response.headers.get("ui-deployment") || "", EStorageType.LocalStorage, true);
                    this._storageManager.store("staff.currentMarcDeployment", result.response.headers.get("marc-deployment") || "", EStorageType.LocalStorage, true);
                    if (currentRequestId === this._requestId) {
                        const data = result.data; //Immutable.List<String>(result.data);

                        this.loadSuccess(pendingOperation);
                        new PermissionActions.LoadSuccess(data);
                    }
                },
                (fail) => {
                    if (currentRequestId === this._requestId) {
                        this.loadFailed(pendingOperation, fail);
                    }
                }
            )
            .catch((errorObj) => {
                if (currentRequestId === this._requestId) {
                    this.loadFailed(pendingOperation, errorObj);
                }
            });
    }
}

const permissionAPI = new PermissionAPI();
export default permissionAPI;
